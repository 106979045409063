$screen-lg: 1240px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 790px,
  xl: 790px
);

$grid-gutter-width: 40px;
$grid-xs-gutter-width: 40px;
$grid-sm-gutter-width: 40px;
$grid-md-gutter-width: 60px;
$grid-lg-gutter-width: 60px;

$grid-gutter-width-base: 40px;
$grid-xs-gutter-width-base: 40px;
$grid-sm-gutter-width-base: 40px;
$grid-md-gutter-width-base: 40px;
$grid-lg-gutter-width-base: 60px;
$grid-xl-gutter-width-base: 60px;

$grid-gutter-widths: (
  xs: $grid-xs-gutter-width-base,
  sm: $grid-sm-gutter-width-base,
  md: $grid-md-gutter-width-base,
  lg: $grid-lg-gutter-width-base,
  xl: $grid-xl-gutter-width-base
);

// Fonts
$font-family--main: 'lato', Arial, sans-serif;
$font-family--regular: 'lato', Arial, sans-serif;
$font-family--bold: 'lato', Arial, sans-serif;
$font--heading: $font-family--bold;
$font--default: $font-family--regular;

// MOBILE: Font sizes (in pixels) / line-heights
$font-size--body: 16;
$line-height--body: 2.6rem;

$font-size--introtext: 18;
$line-height--introtext: 1.56rem;

$font-size--body--small: 12;
$line-height--body--small: 2rem;

$font-size--h1: 34;
$line-height--h1: 3.6rem;
$letter-spacing--h1: 0;

$font-size--h2: 22;
$line-height--h2: 3.2rem;

$font-size--h3: 20;
$line-height--h3: 2.5rem;

$font-size--h4: 18;
$line-height--h4: 2.2rem;

$font-size--lead: 25;
$line-height--lead: 4rem;

$font-size--descriptor: 18;
$line-height--descriptor: 1.8rem;
$letter-spacing--descriptor: 0.5px;

$font-size--button: $font-size--descriptor;
$line-height--button: $line-height--descriptor;
$letter-spacing--button: $letter-spacing--descriptor;

$font-size--quote: $font-size--body;
$line-height--quote: $line-height--body;

$font-size--numbers: 10;
$line-height--numbers: 1rem;

// DESKTOP: Font sizes (in pixels) / line-heights
$desktop--font-size--body: 18;
$desktop--line-height--body: 2.8rem;

$desktop--font-size--body--small: 15;
$desktop--line-height--body--small: 2.2rem;

$desktop--font-size--h1: 55;
$desktop--line-height--h1: 5.5rem;
$desktop--letter-spacing--h1: .8px;

$desktop--font-size--h2: 40;
$desktop--line-height--h2: 5.2rem;

$desktop--font-size--h3: 24;
$desktop--line-height--h3: 3rem;

$desktop--font-size--h4: 18;
$desktop--line-height--h4: 2.5rem;


$desktop--font-size--descriptor: 18;
$desktop--line-height--descriptor: 1.8rem;
$desktop--letter-spacing--descriptor: .5px;

$desktop--font-size--button: $desktop--font-size--descriptor;
$desktop--line-height--button: 2.8rem;
$desktop--letter-spacing--button: $desktop--letter-spacing--descriptor;

$desktop--font-size--quote: $desktop--font-size--body;
$desktop--line-height--quote: $desktop--line-height--body;

$desktop--font-size--introtext: 22;
$desktop--line-height--introtext: 3.2rem;

$desktop--font-size--numbers: 14;
$desktop--line-height--numbers: 1.4rem;

$desktop--paragraphs-padding: 8rem;
$mobile--paragraphs-padding: 3rem;

// Colors
$color--primary: #0073fa;
$color--primary--hover: #005fce;
$color--secondary: #00d896;
$color--secondary--hover: #00bd84;

$color--lime: #00eba0;

$color--primary--light: #ecf5ff;

$color--black: #223B58;//#001b3b;
$color--black--dark: #001b3b;
$color--black--darker: #13191c;
$color--black--light: #263238;
$color--blue--light: #f2f8ff;
$color--grey--second: #889093;
$color--grey: #5f6e81;
$color--grey--darker: #7c90a8;
$color--grey--light: #e2eaf2;
$color--grey--lighter: #f7f9fa;
$color--grey--teaserborder: #aebfd4;
$color--white: #fff;
$color--white--hover: #cde4ff;
$color--white--darker: #faf9f7;
$color--warn: #e10000;
$color--warn--lighter: #fff9f9;
$color--checked: #6fe100;

$color--heading: $color--black;

$color--forms: $color--grey--light;
$color--forms-valid: $color--secondary;
$color--forms-fair: #f5a623;
$color--forms-error: #f15b56;
$color--forms-error--lighter: #fce6e3;
$color--forms-disabled: #7c90a8;
$color--forms-disabled--lighter: #f2f8ff;

$color--transparent: rgba(255, 255, 255, 0);

// TODO: these are not form desing
$color--caption: $color--grey--light;
$color--grey-dark: #999;

//form spacing
$default-input-spacing-top: .75rem;
$default-input-spacing-bottom: .95rem;
$default-input-spacing-left: 2rem;
$default-input-spacing-right: $default-input-spacing-left;
$default-input-spacing: $default-input-spacing-top $default-input-spacing-right $default-input-spacing-bottom $default-input-spacing-left;

//borders
$color--border: $color--grey--light;
$border-radius--primary: 10px;
$border-radius--forms: 8px;
$border-radius--forms-checkbox: 3px;

//shadows
$box-shadow--primary: 0 4px 6px 0 rgba(0, 0, 0, .12);
$box-shadow--secondary: 0 3px 8px 0 rgba(0, 0, 0, .19);
$box-shadow--form: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
$box-shadow--teaser: 0 8px 10px 0 rgba(0, 0, 0, 0.1);

$z-index-0: 0;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 100;
$z-index-5: 1000;
