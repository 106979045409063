//
// Responsive: Utility classes
// --------------------------------------------------


// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
//
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
  width: device-width;
}

// IE10 on Windows Phone 8
// IE10 on WP8 doesn't report CSS pixels, but actual device pixels. In
// other words, say on a Lumia, you'll get 768px as the device width,
// meaning users will see the tablet styles and not phone styles.
//
// Alternatively you can override this with JS (see source below), but
// we won't be doing that here given our limited scope.
//
// Source: http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
@media screen and (max-width: 400px) {
  @-ms-viewport{
    width: 320px;
  }
}

// Hide from screenreaders and browsers
// Credit: HTML5 Boilerplate
.hidden {
  display: none !important;
  visibility: hidden !important;
}

// Visibility utilities

.visible-xs {
  @include responsive-invisibility();
  @media (max-width: $screen-xs-max) {
    @include responsive-visibility();
  }
  &.visible-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility();
    }
  }
  &.visible-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility();
    }
  }
  &.visible-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility();
    }
  }
}
.visible-sm {
  @include responsive-invisibility();
  &.visible-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-visibility();
  }
  &.visible-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility();
    }
  }
  &.visible-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility();
    }
  }
}
.visible-md {
  @include responsive-invisibility();
  &.visible-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility();
    }
  }
  &.visible-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-visibility();
  }
  &.visible-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility();
    }
  }
}
.visible-lg {
  @include responsive-invisibility();
  &.visible-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility();
    }
  }
  &.visible-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility();
    }
  }
  &.visible-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-visibility();
  }
}

// inline-block:
.visible-inline-block-xs {
  @include responsive-invisibility();
  @media (max-width: $screen-xs-max) {
    @include responsive-visibility-inline-block();
  }
  &.visible-inline-block-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-inline-block();
    }
  }
  &.visible-inline-block-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-inline-block();
    }
  }
  &.visible-inline-block-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-inline-block();
    }
  }
}
.visible-inline-block-sm {
  @include responsive-invisibility();
  &.visible-inline-block-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-inline-block();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-visibility-inline-block();
  }
  &.visible-inline-block-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-inline-block();
    }
  }
  &.visible-inline-block-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-inline-block();
    }
  }
}
.visible-inline-block-md {
  @include responsive-invisibility();
  &.visible-inline-block-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-inline-block();
    }
  }
  &.visible-inline-block-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-inline-block();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-visibility-inline-block();
  }
  &.visible-inline-block-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-inline-block();
    }
  }
}
.visible-inline-block-lg {
  @include responsive-invisibility();
  &.visible-inline-block-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-inline-block();
    }
  }
  &.visible-inline-block-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-inline-block();
    }
  }
  &.visible-inline-block-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-inline-block();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-visibility-inline-block();
  }
}

// inline:
.visible-inline-xs {
  @include responsive-invisibility();
  @media (max-width: $screen-xs-max) {
    @include responsive-visibility-inline();
  }
  &.visible-inline-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-inline();
    }
  }
  &.visible-inline-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-inline();
    }
  }
  &.visible-inline-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-inline();
    }
  }
}
.visible-inline-sm {
  @include responsive-invisibility();
  &.visible-inline-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-inline();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-visibility-inline();
  }
  &.visible-inline-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-inline();
    }
  }
  &.visible-inline-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-inline();
    }
  }
}
.visible-inline-md {
  @include responsive-invisibility();
  &.visible-inline-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-inline();
    }
  }
  &.visible-inline-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-inline();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-visibility-inline();
  }
  &.visible-inline-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-inline();
    }
  }
}
.visible-inline-lg {
  @include responsive-invisibility();
  &.visible-inline-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-inline();
    }
  }
  &.visible-inline-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-inline();
    }
  }
  &.visible-inline-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-inline();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-visibility-inline();
  }
}



// table
.visible-table-xs {
  @include responsive-invisibility();
  @media (max-width: $screen-xs-max) {
    @include responsive-visibility-table();
  }
  &.visible-table-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-table();
    }
  }
  &.visible-table-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-table();
    }
  }
  &.visible-table-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-table();
    }
  }
}
.visible-table-sm {
  @include responsive-invisibility();
  &.visible-table-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-table();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-visibility-table();
  }
  &.visible-table-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-table();
    }
  }
  &.visible-table-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-table();
    }
  }
}
.visible-table-md {
  @include responsive-invisibility();
  &.visible-table-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-table();
    }
  }
  &.visible-table-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-table();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-visibility-table();
  }
  &.visible-table-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-table();
    }
  }
}
.visible-table-lg {
  @include responsive-invisibility();
  &.visible-table-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-table();
    }
  }
  &.visible-table-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-table();
    }
  }
  &.visible-table-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-table();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-visibility-table();
  }
}


// table-cell
.visible-table-cell-xs {
  @include responsive-invisibility();
  @media (max-width: $screen-xs-max) {
    @include responsive-visibility-table-cell();
  }
  &.visible-table-cell-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-table-cell();
    }
  }
  &.visible-table-cell-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-table-cell();
    }
  }
  &.visible-table-cell-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-table-cell();
    }
  }
}
.visible-table-cell-sm {
  @include responsive-invisibility();
  &.visible-table-cell-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-table-cell();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-visibility-table-cell();
  }
  &.visible-table-cell-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-table-cell();
    }
  }
  &.visible-table-cell-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-table-cell();
    }
  }
}
.visible-table-cell-md {
  @include responsive-invisibility();
  &.visible-table-cell-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-table-cell();
    }
  }
  &.visible-table-cell-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-table-cell();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-visibility-table-cell();
  }
  &.visible-table-cell-lg {
    @media (min-width: $screen-lg) {
      @include responsive-visibility-table-cell();
    }
  }
}
.visible-table-cell-lg {
  @include responsive-invisibility();
  &.visible-table-cell-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-visibility-table-cell();
    }
  }
  &.visible-table-cell-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-visibility-table-cell();
    }
  }
  &.visible-table-cell-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-visibility-table-cell();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-visibility-table-cell();
  }
}







.hidden-xs {
  @include responsive-visibility();
  @media (max-width: $screen-xs-max) {
    @include responsive-invisibility();
  }
  &.hidden-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-sm {
  @include responsive-visibility();
  &.hidden-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-invisibility();
  }
  &.hidden-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-md {
  @include responsive-visibility();
  &.hidden-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-invisibility();
  }
  &.hidden-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-lg {
  @include responsive-visibility();
  &.hidden-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-invisibility();
  }
}

// inline:
.hidden-inline-xs {
  @include responsive-visibility-inline();
  @media (max-width: $screen-xs-max) {
    @include responsive-invisibility();
  }
  &.hidden-inline-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-inline-sm {
  @include responsive-visibility-inline();
  &.hidden-inline-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-invisibility();
  }
  &.hidden-inline-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-inline-md {
  @include responsive-visibility-inline();
  &.hidden-inline-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-invisibility();
  }
  &.hidden-inline-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-inline-lg {
  @include responsive-visibility-inline();
  &.hidden-inline-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-invisibility();
  }
}

// inline-block:
.hidden-inline-block-xs {
  @include responsive-visibility-inline-block();
  @media (max-width: $screen-xs-max) {
    @include responsive-invisibility();
  }
  &.hidden-inline-block-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-block-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-block-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-inline-block-sm {
  @include responsive-visibility-inline-block();
  &.hidden-inline-block-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-invisibility();
  }
  &.hidden-inline-block-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-block-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-inline-block-md {
  @include responsive-visibility-inline-block();
  &.hidden-inline-block-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-block-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-invisibility();
  }
  &.hidden-inline-block-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-inline-block-lg {
  @include responsive-visibility-inline-block();
  &.hidden-inline-block-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-block-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-inline-block-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-invisibility();
  }
}


// table:
.hidden-table-xs {
  @include responsive-visibility-table();
  @media (max-width: $screen-xs-max) {
    @include responsive-invisibility();
  }
  &.hidden-table-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-table-sm {
  @include responsive-visibility-table();
  &.hidden-table-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-invisibility();
  }
  &.hidden-table-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-table-md {
  @include responsive-visibility-table();
  &.hidden-table-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-invisibility();
  }
  &.hidden-table-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-table-lg {
  @include responsive-visibility-table();
  &.hidden-table-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-invisibility();
  }
}



// table-cell:
.hidden-table-cell-xs {
  @include responsive-visibility-table-cell();
  @media (max-width: $screen-xs-max) {
    @include responsive-invisibility();
  }
  &.hidden-table-cell-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-cell-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-cell-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-table-cell-sm {
  @include responsive-visibility-table-cell();
  &.hidden-table-cell-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    @include responsive-invisibility();
  }
  &.hidden-table-cell-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-cell-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-table-cell-md {
  @include responsive-visibility-table-cell();
  &.hidden-table-cell-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-cell-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-md) and (max-width: $screen-md-max) {
    @include responsive-invisibility();
  }
  &.hidden-table-cell-lg {
    @media (min-width: $screen-lg) {
      @include responsive-invisibility();
    }
  }
}
.hidden-table-cell-lg {
  @include responsive-visibility-table-cell();
  &.hidden-table-cell-xs {
    @media (max-width: $screen-xs-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-cell-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      @include responsive-invisibility();
    }
  }
  &.hidden-table-cell-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
      @include responsive-invisibility();
    }
  }
  @media (min-width: $screen-lg) {
    @include responsive-invisibility();
  }
}

// Print utilities
.visible-print {
  @include responsive-invisibility();
}

@media print {
  .visible-print {
    @include responsive-visibility();
  }
  .hidden-print {
    @include responsive-invisibility();
  }
}
