    //
// Mixins
// --------------------------------------------------




// Utilities
// -------------------------

@mixin perspective() {
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}


// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
    clear: both;
  }
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

@mixin set-animation($params) {
  -webkit-animation: $params;
  -moz-animation: $params;
  -ms-animation: $params;
  -o-animation: $params;
  animation: $params;
}

@mixin animation-fade-in() {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes animation-fade-in {@include animation-fade-in;}
@-moz-keyframes animation-fade-in {@include animation-fade-in;}
@-ms-keyframes animation-fade-in {@include animation-fade-in;}
@-o-keyframes animation-fade-in {@include animation-fade-in;}
@keyframes animation-fade-in {@include animation-fade-in;}

@mixin animation-fade-out () {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes animation-fade-out {@include animation-fade-out;}
@-moz-keyframes animation-fade-out {@include animation-fade-out;}
@-ms-keyframes animation-fade-out {@include animation-fade-out;}
@-o-keyframes animation-fade-out {@include animation-fade-out;}
@keyframes animation-fade-out {@include animation-fade-out;}


// @mixin make-container() {
//   @include container-fixed();
//   @media (min-width: $screen-tablet) {
//     max-width: $screen-tablet;
//   }
//   @media (min-width: $screen-desktop) {
//     max-width: $screen-desktop;
//   }
//   @media (min-width: $screen-lg-desktop) {
//     max-width: $screen-lg-desktop;
//   }
// }

@mixin make-container-width($extra-width: 0) {
  margin: 0 auto;
  @media (min-width: $screen-tablet) {
    max-width: ($screen-tablet + $extra-width);
  }
  @media (min-width: $screen-desktop) {
    max-width: ($screen-desktop + $extra-width);
  }
  @media (min-width: $screen-lg-desktop) {
    max-width: ($screen-lg-desktop + $extra-width);
  }
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin padding($divider: 1) {
  @if $divider > 0 {
    padding: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { padding: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { padding: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { padding: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    padding: 0;
    @media #{$tablet} { padding: 0; }
    @media #{$normal} { padding: 0; }
    @media #{$wide}   { padding: 0; }
  }
}

@mixin padding-left($divider: 1) {
  @if $divider > 0 {
    padding-left: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { padding-left: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { padding-left: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { padding-left: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    padding-left: 0;
    @media #{$tablet} { padding-left: 0; }
    @media #{$normal} { padding-left: 0; }
    @media #{$wide}   { padding-left: 0; }
  }
}

@mixin padding-right($divider: 1) {
  @if $divider > 0 {
    padding-right: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { padding-right: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { padding-right: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { padding-right: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    padding-right: 0;
    @media #{$tablet} { padding-right: 0; }
    @media #{$normal} { padding-right: 0; }
    @media #{$wide}   { padding-right: 0; }
  }
}

@mixin padding-top($divider: 1) {
  @if $divider > 0 {
    padding-top: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { padding-top: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { padding-top: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { padding-top: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    padding-top: 0;
    @media #{$tablet} { padding-top: 0; }
    @media #{$normal} { padding-top: 0; }
    @media #{$wide}   { padding-top: 0; }
  }
}

@mixin padding-bottom($divider: 1) {
  @if $divider > 0 {
    padding-bottom: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { padding-bottom: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { padding-bottom: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { padding-bottom: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    padding-bottom: 0;
    @media #{$tablet} { padding-bottom: 0; }
    @media #{$normal} { padding-bottom: 0; }
    @media #{$wide}   { padding-bottom: 0; }
  }
}

@mixin padding-left-right($divider: 1) {
  padding-left: $grid-xs-gutter-width-base / $divider;
  padding-right: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    padding-left: $grid-sm-gutter-width-base / $divider;
    padding-right: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    padding-left: $grid-md-gutter-width-base / $divider;
    padding-right: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    padding-left: $grid-lg-gutter-width-base / $divider;
    padding-right: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin padding-top-bottom($divider: 1) {
  padding-top: $grid-xs-gutter-width-base / $divider;
  padding-bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    padding-top: $grid-sm-gutter-width-base / $divider;
    padding-bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    padding-top: $grid-md-gutter-width-base / $divider;
    padding-bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    padding-top: $grid-lg-gutter-width-base / $divider;
    padding-bottom: $grid-lg-gutter-width-base / $divider;
  }
}


@mixin padding-left-top($divider: 1) {
  padding-left: $grid-xs-gutter-width-base / $divider;
  padding-top: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    padding-left: $grid-sm-gutter-width-base / $divider;
    padding-top: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    padding-left: $grid-md-gutter-width-base / $divider;
    padding-top: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    padding-left: $grid-lg-gutter-width-base / $divider;
    padding-top: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin padding-left-bottom($divider: 1) {
  padding-left: $grid-xs-gutter-width-base / $divider;
  padding-bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    padding-left: $grid-sm-gutter-width-base / $divider;
    padding-bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    padding-left: $grid-md-gutter-width-base / $divider;
    padding-bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    padding-left: $grid-lg-gutter-width-base / $divider;
    padding-bottom: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin padding-right-top($divider: 1) {
  padding-right: $grid-xs-gutter-width-base / $divider;
  padding-top: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    padding-right: $grid-sm-gutter-width-base / $divider;
    padding-top: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    padding-right: $grid-md-gutter-width-base / $divider;
    padding-top: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    padding-right: $grid-lg-gutter-width-base / $divider;
    padding-top: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin padding-right-bottom($divider: 1) {
  padding-right: $grid-xs-gutter-width-base / $divider;
  padding-bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    padding-right: $grid-sm-gutter-width-base / $divider;
    padding-bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    padding-right: $grid-md-gutter-width-base / $divider;
    padding-bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    padding-right: $grid-lg-gutter-width-base / $divider;
    padding-bottom: $grid-lg-gutter-width-base / $divider;
  }
}

///////////////////////

@mixin margin($divider: 1) {
  @if $divider > 0 {
    margin: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { margin: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { margin: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { margin: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    margin: 0;
    @media #{$tablet} { margin: 0; }
    @media #{$normal} { margin: 0; }
    @media #{$wide}   { margin: 0; }
  }
}

@mixin margin-left($divider: 1) {
  @if $divider > 0 {
    margin-left: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { margin-left: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { margin-left: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { margin-left: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    margin-left: 0;
    @media #{$tablet} { margin-left: 0; }
    @media #{$normal} { margin-left: 0; }
    @media #{$wide}   { margin-left: 0; }
  }
}

@mixin margin-right($divider: 1) {
  @if $divider > 0 {
    margin-right: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { margin-right: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { margin-right: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { margin-right: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    margin-right: 0;
    @media #{$tablet} { margin-right: 0; }
    @media #{$normal} { margin-right: 0; }
    @media #{$wide}   { margin-right: 0; }
  }
}

@mixin margin-top($divider: 1) {
  @if $divider > 0 {
    margin-top: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { margin-top: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { margin-top: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { margin-top: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    margin-top: 0$divider;
    @media #{$tablet} { margin-top: 0; }
    @media #{$normal} { margin-top: 0; }
    @media #{$wide}   { margin-top: 0; }
  }
}

@mixin margin-bottom($divider: 1) {
  @if $divider > 0 {
    margin-bottom: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { margin-bottom: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { margin-bottom: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { margin-bottom: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    margin-bottom: 0;
    @media #{$tablet} { margin-bottom: 0; }
    @media #{$normal} { margin-bottom: 0; }
    @media #{$wide}   { margin-bottom: 0; }
  }
}

@mixin margin-left-right($divider: 1) {
  margin-left: $grid-xs-gutter-width-base / $divider;
  margin-right: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    margin-left: $grid-sm-gutter-width-base / $divider;
    margin-right: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    margin-left: $grid-md-gutter-width-base / $divider;
    margin-right: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    margin-leftt: $grid-lg-gutter-width-base / $divider;
    margin-right: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin margin-top-bottom($divider: 1) {
  margin-top: $grid-xs-gutter-width-base / $divider;
  margin-bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    margin-top: $grid-sm-gutter-width-base / $divider;
    margin-bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    margin-top: $grid-md-gutter-width-base / $divider;
    margin-bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    margin-top: $grid-lg-gutter-width-base / $divider;
    margin-bottom: $grid-lg-gutter-width-base / $divider;
  }
}


@mixin margin-left-top($divider: 1) {
  margin-left: $grid-xs-gutter-width-base / $divider;
  margin-top: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    margin-left: $grid-sm-gutter-width-base / $divider;
    margin-top: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    margin-left: $grid-md-gutter-width-base / $divider;
    margin-top: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    margin-left: $grid-lg-gutter-width-base / $divider;
    margin-top: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin margin-left-bottom($divider: 1) {
  margin-left: $grid-xs-gutter-width-base / $divider;
  margin-bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    margin-left: $grid-sm-gutter-width-base / $divider;
    margin-bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    margin-left: $grid-md-gutter-width-base / $divider;
    margin-bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    margin-left: $grid-lg-gutter-width-base / $divider;
    margin-bottom: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin margin-right-top($divider: 1) {
  margin-right: $grid-xs-gutter-width-base / $divider;
  margin-top: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    margin-right: $grid-sm-gutter-width-base / $divider;
    margin-top: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    margin-right: $grid-md-gutter-width-base / $divider;
    margin-top: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    margin-right: $grid-lg-gutter-width-base / $divider;
    margin-top: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin margin-right-bottom($divider: 1) {
  margin-right: $grid-xs-gutter-width-base / $divider;
  margin-bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    margin-right: $grid-sm-gutter-width-base / $divider;
    margin-bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    margin-right: $grid-md-gutter-width-base / $divider;
    margin-bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    margin-right: $grid-lg-gutter-width-base / $divider;
    margin-bottom: $grid-lg-gutter-width-base / $divider;
  }
}
///////////////////////////

@mixin top-right-bottom-left($divider: 1) {
  top: $grid-xs-gutter-width-base / $divider;
  right: $grid-xs-gutter-width-base / $divider;
  bottom: $grid-xs-gutter-width-base / $divider;
  left: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    top: $grid-sm-gutter-width-base / $divider;
    right: $grid-sm-gutter-width-base / $divider;
    bottom: $grid-sm-gutter-width-base / $divider;
    left: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    top: $grid-md-gutter-width-base / $divider;
    right: $grid-md-gutter-width-base / $divider;
    bottom: $grid-md-gutter-width-base / $divider;
    left: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    top: $grid-lg-gutter-width-base / $divider;
    right: $grid-lg-gutter-width-base / $divider;
    bottom: $grid-lg-gutter-width-base / $divider;
    left: $grid-lg-gutter-width-base / $divider;
  }
}

// right: auto;
@mixin top-bottom-left($divider: 1) {
  top: $grid-xs-gutter-width-base / $divider;
  bottom: $grid-xs-gutter-width-base / $divider;
  left: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    top: $grid-sm-gutter-width-base / $divider;
    bottom: $grid-sm-gutter-width-base / $divider;
    left: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    top: $grid-md-gutter-width-base / $divider;
    bottom: $grid-md-gutter-width-base / $divider;
    left: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    top: $grid-lg-gutter-width-base / $divider;
    bottom: $grid-lg-gutter-width-base / $divider;
    left: $grid-lg-gutter-width-base / $divider;
  }
}

// left: auto;
@mixin top-right-bottom($divider: 1) {
  top: $grid-xs-gutter-width-base / $divider;
  right: $grid-xs-gutter-width-base / $divider;
  bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    top: $grid-sm-gutter-width-base / $divider;
    right: $grid-sm-gutter-width-base / $divider;
    bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    top: $grid-md-gutter-width-base / $divider;
    right: $grid-md-gutter-width-base / $divider;
    bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    top: $grid-lg-gutter-width-base / $divider;
    right: $grid-lg-gutter-width-base / $divider;
    bottom: $grid-lg-gutter-width-base / $divider;
  }
}

// bottom: auto;
@mixin top-right-left($divider: 1) {
  top: $grid-xs-gutter-width-base / $divider;
  right: $grid-xs-gutter-width-base / $divider;
  left: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    top: $grid-sm-gutter-width-base / $divider;
    right: $grid-sm-gutter-width-base / $divider;
    left: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    top: $grid-md-gutter-width-base / $divider;
    right: $grid-md-gutter-width-base / $divider;
    left: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    top: $grid-lg-gutter-width-base / $divider;
    right: $grid-lg-gutter-width-base / $divider;
    left: $grid-lg-gutter-width-base / $divider;
  }
}

// top: auto;
@mixin right-bottom-left($divider: 1) {
  right: $grid-xs-gutter-width-base / $divider;
  bottom: $grid-xs-gutter-width-base / $divider;
  left: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    right: $grid-sm-gutter-width-base / $divider;
    bottom: $grid-sm-gutter-width-base / $divider;
    left: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    right: $grid-md-gutter-width-base / $divider;
    bottom: $grid-md-gutter-width-base / $divider;
    left: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    right: $grid-lg-gutter-width-base / $divider;
    bottom: $grid-lg-gutter-width-base / $divider;
    left: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin right($divider: 1) {
  @if $divider > 0 {
    right: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { right: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { right: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { right: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    right: 0;
    @media #{$tablet} { right: 0; }
    @media #{$normal} { right: 0; }
    @media #{$wide}   { right: 0; }
  }
}

@mixin top($divider: 1) {
  @if $divider > 0 {
    top: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { top: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { top: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { top: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    top: 0;
    @media #{$tablet} { top: 0; }
    @media #{$normal} { top: 0; }
    @media #{$wide}   { top: 0; }
  }
}

@mixin bottom($divider: 1) {
  @if $divider > 0 {
    bottom: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { bottom: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { bottom: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { bottom: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    bottom: 0;
    @media #{$tablet} { bottom: 0; }
    @media #{$normal} { bottom: 0; }
    @media #{$wide}   { bottom: 0; }
  }
}

@mixin left($divider: 1) {
  @if $divider > 0 {
    left: $grid-xs-gutter-width-base / $divider;
    @media #{$tablet} { left: $grid-sm-gutter-width-base / $divider; }
    @media #{$normal} { left: $grid-md-gutter-width-base / $divider; }
    @media #{$wide}   { left: $grid-lg-gutter-width-base / $divider; }
  }
  @else if $divider == 0 {
    left: 0;
    @media #{$tablet} { left: 0; }
    @media #{$normal} { left: 0; }
    @media #{$wide}   { left: 0; }
  }
}

@mixin left-right($divider: 1) {
  right: $grid-xs-gutter-width-base / $divider;
  left: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    right: $grid-sm-gutter-width-base / $divider;
    left: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    right: $grid-md-gutter-width-base / $divider;
    left: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    right: $grid-lg-gutter-width-base / $divider;
    left: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin top-bottom($divider: 1) {
  top: $grid-xs-gutter-width-base / $divider;
  bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    top: $grid-sm-gutter-width-base / $divider;
    bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    top: $grid-md-gutter-width-base / $divider;
    bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    top: $grid-lg-gutter-width-base / $divider;
    bottom: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin left-top($divider: 1) {
  left: $grid-xs-gutter-width-base / $divider;
  top: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    left: $grid-sm-gutter-width-base / $divider;
    top: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    left: $grid-md-gutter-width-base / $divider;
    top: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    left: $grid-lg-gutter-width-base / $divider;
    top: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin left-bottom($divider: 1) {
  left: $grid-xs-gutter-width-base / $divider;
  bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    left: $grid-sm-gutter-width-base / $divider;
    bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    left: $grid-md-gutter-width-base / $divider;
    bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    left: $grid-lg-gutter-width-base / $divider;
    bottom: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin right-top($divider: 1) {
  right: $grid-xs-gutter-width-base / $divider;
  top: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    right: $grid-sm-gutter-width-base / $divider;
    top: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    right: $grid-md-gutter-width-base / $divider;
    top: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    right: $grid-lg-gutter-width-base / $divider;
    top: $grid-lg-gutter-width-base / $divider;
  }
}

@mixin right-bottom($divider: 1) {
  right: $grid-xs-gutter-width-base / $divider;
  bottom: $grid-xs-gutter-width-base / $divider;
  @media #{$tablet} {
    right: $grid-sm-gutter-width-base / $divider;
    bottom: $grid-sm-gutter-width-base / $divider;
  }
  @media #{$normal} {
    right: $grid-md-gutter-width-base / $divider;
    bottom: $grid-md-gutter-width-base / $divider;
  }
  @media #{$wide}   {
    right: $grid-lg-gutter-width-base / $divider;
    bottom: $grid-lg-gutter-width-base / $divider;
  }
}
///////////////////////////

// Webkit-style focus
@mixin tab-focus() {
  // Default
  outline: thin dotted #333;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
  width: $width;
  height: $height;
}
@mixin square($size) {
  @include size($size, $size);
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  &:-moz-placeholder            { color: $color; } // Firefox 4-18
  &::-moz-placeholder           { color: $color; } // Firefox 19+
  &:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CSS image replacement
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
@mixin hide-text() {
  font: "0/0" a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin border-image($args) {
  -moz-border-image: $args;
  -webkit-border-image: $args;
  -o-border-image: $args;
  border-image: $args;
}

// CSS3 PROPERTIES
// --------------------------------------------------

// Single side border-radius
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
   border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
     border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
   border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
     border-top-left-radius: $radius;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
          box-shadow: $shadow;
}

// Transitions
@mixin transition($transition...) {
  -webkit-transition: $transition;
          transition: $transition;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}
@mixin transition-transform($transition) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}

// Transformations
@mixin transform($args) {
  -webkit-transform: $args;
      -ms-transform: $args; // IE9+
          transform: $args;
}
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9+
          transform: rotate($degrees);
}
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
      -ms-transform: scale($ratio); // IE9+
          transform: scale($ratio);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9+
          transform: translate($x, $y);
}
@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
      -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
          transform: skew($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
@mixin backface-visibility($visibility) {
  -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select; // IE10+
       -o-user-select: $select;
          user-select: $select;
}

// Resize anything
@mixin resizable($direction) {
  resize: $direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
     -moz-column-count: $column-count;
          column-count: $column-count;
  -webkit-column-gap: $column-gap;
     -moz-column-gap: $column-gap;
          column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
     -moz-hyphens: $mode;
      -ms-hyphens: $mode; // IE10+
       -o-hyphens: $mode;
          hyphens: $mode;
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: "alpha(opacity=#{$opacity-ie})";
}



// GRADIENTS
// --------------------------------------------------

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-gradient(linear, $start-percent top, $end-percent top, from($start-color), to($end-color)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(left, color-stop($start-color $start-percent), color-stop($end-color $end-percent)); // Safari 5.1+, Chrome 10+
  background-image: -moz-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // FF 3.6+
  background-image:  linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10
  background-repeat: repeat-x;
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1),argb($start-color),argb($end-color); // IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-gradient(linear, left $start-percent, left $end-percent, from($start-color), to($end-color)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $start-color, $start-percent, $end-color, $end-percent); // Safari 5.1+, Chrome 10+
  background-image:  -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); // FF 3.6+
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0),argb($start-color),argb($end-color); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1+, Chrome 10+
  background-image: -moz-linear-gradient($deg, $start-color, $end-color); // FF 3.6+
  background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10
}
@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-gradient(left, linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
  background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: -moz-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1),argb($start-color),argb($end-color); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -moz-linear-gradient(top, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0),argb($start-color),argb($end-color); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($inner-color), to($outer-color));
  background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
  background-image: -moz-radial-gradient(circle, $inner-color, $outer-color);
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: #555, $angle: 45deg) {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
}

// Reset filters for IE
//
// When you need to remove a gradient background, do not forget to use this to reset
// the IE filter for IE9 and below.
@mixin reset-filter() {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}



// Retina images
//
// Short retina mixin for setting background-image and -size

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url("#{$file-1x}");

  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    background-image: url("#{$file-2x}");
    background-size: $width-1x $height-1x;
  }
}


// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}


// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------------
// Dividers (basically an hr) within dropdowns and nav lists
@mixin nav-divider($color: #e5e5e5) {
  height: 1px;
  margin: (($line-height-computed / 2) - 1) 0;
  overflow: hidden;
  background-color: $color;
}

// Panels
// -------------------------
@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;
  & > .panel-heading {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;
    + .panel-collapse .panel-body {
      border-top-color: $border;
    }
  }
  & > .panel-footer {
    + .panel-collapse .panel-body {
      border-bottom-color: $border;
    }
  }
}

// Alerts
// -------------------------
@mixin alert-variant($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color;
  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($text-color, 10%);
  }
}

// Tables
// -------------------------
@mixin table-row-variant($state, $background, $border) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.#{$state},
    > th.#{$state},
    &.#{$state} > td,
    &.#{$state} > th {
      background-color: $background;
      border-color: $border;
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover > tbody > tr {
    > td.#{$state}:hover,
    > th.#{$state}:hover,
    &.#{$state}:hover > td {
      background-color: darken($background, 5%);
      border-color: darken($border, 5%);
    }
  }
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open .dropdown-toggle {
    color: $color;
    background-color: darken($background, 8%);
        border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open .dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
          border-color: $border
    }
  }
}

// Button sizes
// -------------------------
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

// Pagination
// -------------------------
@mixin pagination-size($padding-vertical, $padding-horizontal, $font-size, $border-radius) {
  > li {
    > a,
    > span {
      padding: $padding-vertical $padding-horizontal;
      font-size: $font-size;
    }
    &:first-child {
      > a,
      > span {
        @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius($border-radius);
      }
    }
  }
}

// Labels
// -------------------------
@mixin label-variant($color) {
  background-color: $color;
  &[href] {
    &:hover,
    &:focus {
      background-color: darken($color, 10%);
    }
  }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.
@mixin navbar-vertical-align($element-height) {
  margin-top: (($navbar-height - $element-height) / 2);
  margin-bottom: (($navbar-height - $element-height) / 2);
}

// Progress bars
// -------------------------
@mixin progress-bar-variant($color) {
  background-color: $color;
  .progress-striped & {
    @include gradient-striped($color);
  }
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.scss.
@mixin responsive-visibility() {
  display: block !important;
  tr { display: table-row !important; }
  th,
  td{ display: table-cell !important; }
}
@mixin responsive-visibility-inline-block() {
  display: inline-block !important;
  tr { display: table-row !important; }
  th,
  td { display: table-cell !important; }
}

@mixin responsive-visibility-inline() {
  display: inline !important;
  tr { display: table-row !important; }
  th,
  td { display: table-cell !important; }
}

@mixin responsive-visibility-table() {
  display: table !important;
}
@mixin responsive-visibility-table-cell() {
  display: table-cell !important;
}

@mixin responsive-invisibility() {
  display: none !important;
  tr { display: none !important; }
  th,
  td { display: none !important; }
}

// Grid System
// -----------

// Centered container element
@mixin container-fixed() {
  margin-right: auto;
  margin-left: auto;

  padding: ($grid-xs-gutter-width-base / 1) $grid-xs-gutter-width-base;

  @media #{$tablet} {
    padding: ($grid-sm-gutter-width-base / 1) $grid-sm-gutter-width-base;
  }
  @media #{$normal} {
    padding: ($grid-md-gutter-width-base / 1) $grid-md-gutter-width-base;
  }
  @media #{$wide} {
    padding: ($grid-lg-gutter-width-base / 1) $grid-lg-gutter-width-base;
  }
  // .clearfix(); // is deze wel nodig?
  clear: left;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-xs-gutter-width-base) {
  margin: ($gutter / -2);

  @media #{$tablet} {
    margin: ($grid-sm-gutter-width-base / -2);
  }
  @media #{$normal} {
    margin: ($grid-md-gutter-width-base / -2);
  }
  @media #{$wide} {
    margin: ($grid-lg-gutter-width-base / -2);
  }
  @include clearfix(); // is deze wel nodig?
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-xs-gutter-width-base) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding: ($gutter / 2);
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-sm-gutter-width-base) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding

  // Calculate width based on number of columns available
  @media (min-width: $screen-sm) {
    float: left;
    width: percentage(($columns / $grid-columns));
    padding: ($gutter / 2);
  }
}

// Generate the small column offsets
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-md-gutter-width-base) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding

  // Calculate width based on number of columns available
  @media (min-width: $screen-md) {
    float: left;
    width: percentage(($columns / $grid-columns));
    padding: ($gutter / 2);
  }
}

// Generate the large column offsets
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-lg-gutter-width-base) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding

  // Calculate width based on number of columns available
  @media (min-width: $screen-lg) {
    float: left;
    width: percentage(($columns / $grid-columns));
    padding: ($gutter / 2);
  }
}

// Generate the large column offsets
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg) {
    right: percentage(($columns / $grid-columns));
  }
}


// Form validation states
//
// Used in forms.scss to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken($border-color, 10%);
      $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
      @include box-shadow($shadow);
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 0;
    @include box-shadow("inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #{$color-rgba}");
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

@mixin input-size($input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  height: $input-height;
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;

  select {
    height: $input-height;
    line-height: $input-height;
  }

  textarea {
    height: auto;
  }
}

@mixin display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-row {
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

@mixin flex-row-reverse {
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@mixin center-vertical-flex {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin center-horizontal-flex {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin center-all-flex {
  @include center-horizontal-flex;
  @include center-vertical-flex;
}

@mixin animation($name, $timing: 2.5s) {
  -webkit-animation: $name $timing;
  -moz-animation: $name $timing;
  -ms-animation: $name $timing;
  -o-animation: $name $timing;
  animation: $name $timing;
}

@mixin font-size($size) {
  font-size: $size * 1px;
  font-size: $size * 0.1rem;
}

@mixin border-box() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition:    $transition;
  -ms-transition:     $transition;
  -o-transition:      $transition;
}

@mixin font-size($size) {
  font-size: $size * 1px;
  font-size: $size * 0.1rem;
}
