.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.m-t-1 {
  @media #{$normal} {
    margin-top: 2rem;
  }
}
.m-t-1-m {
  margin-top: 1rem;

  @media #{$normal} {
    margin-top: 2rem;
  }
}

.m-b-1 {
  @media #{$normal} {
    margin-bottom: 2rem;
  }
}

.m-b-1-m {
  margin-bottom: 2rem;
}

.m-b-2 {
  @media #{$normal} {
    margin-bottom: 3rem;
  }
}

.d-block {
  display: block;
  width: 100%;
}

.float-right {
  float: right;
}
