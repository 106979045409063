/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutters: $grid-gutter-widths) {
  position: relative;
  margin-left: auto;
  margin-right: auto;

  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      padding: ($gutter / 2);
    }
  }
}

@mixin make-gutters($gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      padding: ($gutter / 2);
    }
  }
}

@mixin make-row($gutters: $grid-gutter-widths) {
  display: flex;
  flex-wrap: wrap;

  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      margin: ($gutter / -2);
    }
  }
}