// Base layout.
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

@import 'core/_variables';
@import 'core/_mixins';


// Overwrite basetheme variables and mixins when needed.
@import './assets/variables';
@import './assets/mixins';

// Unc Inc base theme.
@import '../../node_modules/bootstrap/scss/mixins/_grid-framework';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/grid';

@import 'core/_grid';
@import 'core/_responsive-utilities';

@import './assets/variables';
@import './assets/mixins';
@import './assets/fonts';
@import './assets/regions';
// @import './core/assets/icons';

// Assets
@import './assets/base';
// @import './core/assets/layout';
// @import './core/assets/classes';
@import './assets/typography';

@import './typography/fontawesome';

// navigation
@import 'components/header';
@import 'components/footer';

// end of list
@import './assets/flex';
@import './assets/addons';
// @import './assets/print';

body {
  background-image: url("../images/main.jpg");
  background-size: cover;
  background-position: center bottom;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.3);
  }
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}

h1 {
  max-width: 260px;
  margin: 0;
  color: #fff;
  text-align: center;

  @media #{$tablet} {
    max-width: 100%;
  }
}