body {
  @include font-size($font-size--body);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $color--grey;
  font-family: $font-family--main;
  line-height: $line-height--body;

  @media #{$normal} {
    @include font-size($desktop--font-size--body);
    line-height: $desktop--line-height--body;
  }
}

@mixin body {
  @include font-size($font-size--body);
  line-height: $line-height--body;

  @media #{$normal} {
    @include font-size($desktop--font-size--body);
    line-height: $desktop--line-height--body;
  }
}

@mixin body--small {
  @include font-size($font-size--body--small);
  line-height: $line-height--body--small;

  @media #{$normal} {
    @include font-size($desktop--font-size--body--small);
    line-height: $desktop--line-height--body--small;
  }
}

%heading_base {
  font-family: $font--heading;
  &:first-child {
    margin-top: 0;
  }
  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

@mixin h1 {
  @extend %heading_base;
  @include font-size($font-size--h1);
  font-weight: normal;
  letter-spacing: $letter-spacing--h1;
  line-height: $line-height--h1;
  margin: 2rem 0;
  color: $color--heading;

  @media #{$tablet} {
    @include font-size($desktop--font-size--h1);
    letter-spacing: $desktop--letter-spacing--h1;
    line-height: $desktop--line-height--h1;
    margin-bottom: 3rem;
  }
}

h1 {
  @include h1;
}

@mixin h2 {
  @extend %heading_base;
  @include font-size($font-size--h2);
  line-height: $line-height--h2;
  margin: 2rem 0;
  color: $color--heading;

  @media #{$normal} {
    @include font-size($desktop--font-size--h2);
    line-height: $desktop--line-height--h2;
    margin-bottom: 1.5rem;
  }
}

h2 {
  @include h2;
}

@mixin h3 {
  @extend %heading_base;
  @include font-size($font-size--h3);
  line-height: $line-height--h3;
  margin: 1.5rem 0;
  color: $color--heading;

  @media #{$normal} {
    @include font-size($desktop--font-size--h3);
    line-height: $desktop--line-height--h3;
  }
}

h3 {
  @include h3;
}

@mixin h4 {
  @extend %heading_base;
  @include font-size($font-size--h4);
  line-height: $line-height--h4;
  margin: 1rem 0;
  color: $color--heading;
  @media #{$normal} {
    @include font-size($desktop--font-size--h4);
    line-height: $desktop--line-height--h4;
  }
}

h4 {
  @include h4;
}

p {
  margin-bottom: 2rem;
  ul li & {
    margin-bottom: .5rem;
  }
}
ul {
  .block-content-wrapper & {
    margin-bottom: 2rem;
  }
}
p.lead {
  @include font-size($font-size--lead);
  line-height: $line-height--lead;
  margin-bottom: 4rem;
  color: $color--black;
}

strong, .strong {
  font-family: $font-family--bold;
  font-weight: normal;
}
small,
.text-size--small {
  font-size: .8em;
}

.text-right {
  text-align: right;
}
