.flex {
  &--end {
    &--md {
      @media #{$normal} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-content: stretch;
        align-items: flex-start;
      }
    }
  }
  &--space-between {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    
    &--reverse {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &--sm {
      @media #{$normal-down} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: stretch;
        align-items: flex-start;
      }
    }
  }
}
