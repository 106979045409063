html {
  font-size: 62.5%;
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
main {
  flex: 1 1 auto;
}

.is--hidden {
  display: none;
}

.dialog-off-canvas-main-canvas {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.reset-position-relative {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  .main-container {
    flex: 1 1 auto;
  }
  .region.region-highlighted {
    margin-top: 0 !important;
  }
}
img {
  max-width: 100%;
  height: auto;
  .m-rounded-borders-img & {
    border-radius: $border-radius--primary;
  }
}
table {
  border: none;
  tr {
    td {
      border: none;
      padding: 0.8rem 1.5rem;
    }
    &:nth-child(2n) {
      background: $color--forms-disabled--lighter
    }
  }
  &.inverse-color {
    tr {
      &:nth-child(2n) {
        background: transparent;
      }
      &:nth-child(2n+1) {
        background: $color--forms-disabled--lighter
      }
    }
  }
}
.main-container > .container {
  padding-bottom: 40px;
}

.field__item {
  position: relative;

  @media #{$normal-down} {
    margin-bottom: 2rem;
  }
}