header.header {
  width: 100%;
  max-width: calc(100% - 20px);
  min-height: 7rem;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  @media #{$tablet} {
    max-width: 1024px;
  }

  .logo {
    width: 120px;
    height: 80px;
    padding: 10px;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;

    @media #{$tablet} {
      width: 150px;
      height: 110px;
      padding: 20px;
    }

    img {
      position: relative;
    }
  }
}