footer.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 20px;
    text-align: center;
    font-size: 1.6rem;
    color: #fff;

    p {
        margin: 0;
    }
    a {
        margin-left: 5px;
    }
}