@mixin icon-grid-svg($pos-x: 0, $pos-y: 0, $size: 100%) {
  background-image: url('../images/icons/icon_grid.png');
  background-image: url('../images/icons/icon_grid.svg');
  background-position: $pos-x $pos-y;
  background-repeat: no-repeat;
  background-size: 26em 11em;
  content: '';
  display: inline-block;
  font-size: $size;
  height: 1em;
  vertical-align: middle;
  width: 1em;
}

@mixin icon-grid-svg--second-color($pos-x: 0, $pos-y: 0) {
  #{$knowledge-hub--class} & {
    background-position: $pos-x $pos-y;
  }
}

@mixin icon-grid-form-svg($pos-x: 0, $pos-y: 0, $size: 100%) {
  background-image: url('../images/icons/icongrid_form.png');
  background-image: url('../images/icons/icongrid_form.svg');
  background-position: $pos-x $pos-y;
  background-repeat: no-repeat;
  background-size: 4em 2em;
  content: '';
  display: inline-block;
  font-size: $size;
  height: 1em;
  vertical-align: middle;
  width: 1em;
}

@mixin button-font-style {
  @include font-size($font-size--button);
  font-family: $font--heading;
  letter-spacing: $letter-spacing--button;
  line-height: $line-height--button;

  @media #{$normal} {
    @include font-size($desktop--font-size--button);
    letter-spacing: $desktop--letter-spacing--button;
    line-height: $desktop--line-height--button;
  }

  &:hover {
    text-decoration: none;
  }
}

@mixin descriptor-font-style {
  @include font-size($font-size--descriptor);
  font-family: $font--heading;
  font-weight: bold;
  letter-spacing: $letter-spacing--descriptor;
  line-height: $line-height--descriptor;
  text-transform: uppercase;

  @media #{$normal} {
    @include font-size($desktop--font-size--descriptor);
    letter-spacing: $desktop--letter-spacing--descriptor;
    line-height: $desktop--line-height--descriptor;
  }
}

@mixin numbers-font-style {
  @include font-size($font-size--numbers);
  font-family: $font--heading;
  // letter-spacing: $letter-spacing--numbers;

  @media #{$normal} {
    @include font-size($desktop--font-size--numbers);
    // letter-spacing: $desktop--letter-spacing--numbers;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  // fallback for old browsers
  background-color: $color;
  background-color: rgba($color, $opacity);
}

@mixin title-underline(
  $padding-left: 0,
  $padding-right: 0,
  $color: $color--primary--lighter,
  $animation: 'true'
) {
  background-image: linear-gradient(180deg, transparent 55%, $color 0);
  background-repeat: no-repeat;
  display: inline;
  position: relative;
  text-decoration: none;
  transition: background-size 0.4s ease;
  vertical-align: baseline;
  z-index: 1;

  @if $animation == 'true' {
    background-size: 0 100%;

    &:active,
    &:focus,
    &:hover {
      background-size: 100% 100%;
      cursor: pointer;
    }
  } @else {
    background-size: 100% 100%;
  }
}

@mixin title-underline--second-color(
  $color: $color--secondary--lighter,
  $animation: 'true'
) {
  #{$knowledge-hub--class} & {
    background-image: linear-gradient(180deg, transparent 55%, $color 0);
  }
}

@mixin muliple-style(
  $prop: 'color',
  $key: $color--primary,
  $key--second: $color--secondary,
  $key--third: $color--grey
) {
  #{$prop}: $key;

  #{$knowledge-hub--class} & {
    #{$prop}: $key--second;
  }

  #{$general--class} & {
    #{$prop}: $key--third;
  }
}

@mixin brenger-curve {
  position: relative;
  left: -5px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 17px;
    height: 10px;
    background: url(../images/smiley_down.svg) 0 0 /cover no-repeat;
    transform: translate3d(-50%,-50%,0);
  }
}

@mixin brenger-curve-double {
  @include brenger-curve;
  &:after {
    height: 17px;
    background: url(../images/smiley_double.svg) 0 0 /cover no-repeat;
  }
}
